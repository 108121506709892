import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#3366ff',
    },
    blue: {
      main: '#4065F6',
    },
    color_dark_text: {
      main: '#222b45',
    },
    color_white: {
      main: '#ffffff',
    },
    color_black: {
      main: '#000000',
    },
    color_light_grey: {
      main: '#e4e9f2',
    },

    color_text_grey: {
      main: '#8f9bb3',
    },
    color_icon_button: {
      main: '#4180e9',
    },
    success: {
      main: '#00e58f',
    },
    warning: {
      main: '#ffaa00',
    },
    info: {
      main: '#3366ff',
    },
    error: {
      main: '#ff116e',
    },
    promo_background: {
      main: '#ffea26',
    },
    color_dark_green: {
      main: '#0A7013',
    },
  },
  typography: {
    fontFamily: 'Lato',
  },
});

lightTheme.overrides = {
  MuiInput: {
    root: {
      padding: 8,
      borderRadius: 4,
      backgroundColor: '#e4e9f2',
      color: '#000000',
    },
  },
};

lightTheme.props = {
  MuiInput: {
    disableUnderline: true,
  },
};

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#1AF192',
    },
  },
  typography: {
    fontFamily: 'Lato',
  },
});

export { lightTheme, darkTheme };
