import produce from 'immer';
import {
  UPDATE_USER_DATA,
  LOGIN_USER,
  LOGOUT_USER,
} from '../actions/action_types';

const INITIAL_STATE = {
  userData: {
    user_id: '',
    username: '',
  },
  isLoggedIn: false,
  permissions: {
    can_refund: false,
    can_edit: false,
    can_add: false,
    can_delete: false,
    allow_analytics: false,
    allow_users_create: false,
  },
};

const user = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_USER_DATA:
      draft.userData = action.payload.userData;
      draft.permissions = action.payload.permissions;
      break;
    case LOGIN_USER:
      draft.isLoggedIn = true;
      break;
    case LOGOUT_USER:
      draft.isLoggedIn = false;
      break;
  }
}, INITIAL_STATE);

export default user;
