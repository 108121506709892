import React, { memo, useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { Header, NavigationTopBar } from '../../components';
import { FormattedMessage, useIntl } from 'react-intl';
import MaterialTable, { Icons } from 'material-table';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert, Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get } from 'lodash';
import {
  bulkUserCreate,
  bulkUserCreateTemplate,
} from 'src/services/userCreation';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100vh',
  },
  bodyContainer: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: `calc(100% - ${140}px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${120}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      height: `calc(100% - ${100}px)`,
    },
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  loader: {
    height: 'auto',
    overflow: 'auto',
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  body: {
    flex: 1,
    overflow: 'auto',
    paddingBottom: 100,
    paddingTop: 20,
  },
  footer: {
    display: 'flex',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    padding: 20,
    zIndex: 999,
    [theme.breakpoints.between('md', 'xl')]: {
      left: 350,
    },
    left: 0,
    right: 0,
  },
  detailPanelIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonLeft: {
    width: '30%',
    marginLeft: '16px',
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}1A`,
    border: (props: any) => `1px solid ${theme.palette[props.b1bg].main}`,
    height: '40px',
    fontWeight: 'bold',
    borderWidth: 1,
    color: (props: any) => theme.palette[props.b1bg].main,
  },
  buttonRight: {
    width: '30%',
    backgroundColor: (props: any) => `${theme.palette[props.b2bg].main}`,
    color: theme.palette.color_white.main,
    marginLeft: 16,
    height: '40px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: (props: any) => `${theme.palette[props.b2bg].main}`,
    },
  },
  buttonLeftRipple: {
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}1A`,
  },
  buttonRightRipple: {
    color: (props: any) => `${theme.palette[props.b2bg].main}`,
  },
}));
const tableIcons: Icons = {
  DetailPanel: React.forwardRef((props, ref) => {
    return <></>;
  }),
};
const UserCreation = () => {
  const classes = useStyles({
    b1bg: 'blue',
    b2bg: 'blue',
  });
  const theme: any = useTheme();
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const intl = useIntl();
  const active_stores = useSelector((state: any) => state.store.active_stores);
  const { pageNumber, pageSize = 10 } = useSelector(
    (state: any) => state.orders,
  );
  const { cashReport: page = 1 } = pageNumber;
  const inputFile = useRef(null);
  const [loader, setLoader] = useState(false);
  const [uploadData, setUploadData] = useState('');
  const [promoData, setPromoData] = React.useState([]);
  const [toast, showToast] = useState<any>({
    visible: false,
    message: '',
    severity: 'error',
  });
  const handlePagination = (event: any, value: any) => {
    dispatch({
      type: 'CHANGE_PAGE_NUMBER',
      payload: {
        ...pageNumber,
        cashReport: value,
      },
    });
  };

  const downloadUserCreationTemplate = async data => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    let fileName = 'user_data.csv';
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  const fetchUserCreationData = async (download?) => {
    setLoader(true);
    let params: any = {
      page,
      page_size: pageSize,
      token: cookies.app_token,
    };
    if (typeof download === 'boolean') {
      params.download = true;
    }
    return bulkUserCreate
      .get(params)
      .then((res: any) => {
        setLoader(false);
        if (params.download) {
          downloadUserCreationTemplate(res.data);
        }
        return res.data.data || {};
      })
      .catch(e => {
        setLoader(false);
        showToast({
          ...toast,
          visible: true,
          message: e,
          severity: 'error',
        });
      });
  };
  const downloadTemplate = async () => {
    setLoader(true);
    let params: any = {
      store_id: active_stores[0].store_id,
      page,
      page_size: pageSize,
      token: cookies.app_token,
    };
    return bulkUserCreateTemplate
      .get(params)
      .then((res: any) => {
        setLoader(false);
        downloadUserCreationTemplate(res.data);
      })
      .catch(e => {
        setLoader(false);
        showToast({
          ...toast,
          visible: true,
          message: e,
          severity: 'error',
        });
      });
  };
  const { isLoading: isPromoLoading, data: userCreationData } = useQuery(
    ['UserCreationData', page, active_stores, pageSize],
    fetchUserCreationData,
    {
      refetchOnWindowFocus: false,
    },
  );
  const { isLoading: loading, mutate: promoMutate } = useMutation(
    ['UserCreationData', page, active_stores, pageSize],
    fetchUserCreationData,
    {
      onSuccess: (userCreationData: any) => {
        setPromoData(userCreationData);
        queryClient.invalidateQueries({ queryKey: ['results'] });
        queryClient.refetchQueries({ queryKey: ['results'] });
      },
    },
  );

  useEffect(() => {
    setPromoData(userCreationData);
  }, [userCreationData]);

  const hideToast = () => {
    showToast({
      ...toast,
      visible: false,
    });
  };
  const uploadUserData = async (file?) => {
    console.log('user');
    setLoader(true);
    let params: any = {
      v1: 'base_v2',
      store_id: active_stores[0].store_id,
      page,
      token: cookies.app_token,
    };
    return bulkUserCreate
      .file(params, {
        store_id: active_stores[0].store_id,
        file: file,
      })
      .then((res: any) => {
        setLoader(false);
        res?.data?.data?.status_message && !res?.data?.data?.status_message.includes("0 users ") &&
          showToast({
            ...toast,
            visible: true,
            message: res?.data?.data?.status_message,
            severity: 'success',
          });
        setTimeout(() => {
          res?.data?.data?.invalid_data &&
            res?.data?.data?.invalid_data?.some(error => {
              showToast({
                ...toast,
                visible: true,
                message: error,
                severity: 'error',
              });
            });
        }, 1000);

        setTimeout(() => {
          promoMutate({});
        }, 500);
        return res.data || {};
      })
      .catch(e => {
        setLoader(false);
        showToast({
          ...toast,
          visible: true,
          message: e,
          severity: 'error',
        });
      });
  };
  const onChangeFile = async event => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    console.log(file);
    await setUploadData(file);
    uploadUserData(file);
  };

  const renderFooterContent = () => {
    let items_available = get(promoData, 'users', []);
    let requiredStatus =
      items_available.length >= 1 ? 'download_table' : 'download_template';
    switch (requiredStatus) {
      case 'download_template':
        return (
          <Box
            className={classes.footer}
            borderTop={1}
            borderColor={theme.palette.color_light_grey.main}
            style={{ position: 'fixed' }}
          >
            <Button
              className={classes.buttonRight}
              variant="outlined"
              TouchRippleProps={{
                classes: { root: classes.buttonRightRipple },
              }}
              onClick={() => downloadTemplate()}
            >
              <FormattedMessage
                id="downloadTemplate"
                defaultMessage="Download Template"
              />
            </Button>
            <Button
              className={classes.buttonLeft}
              variant="outlined"
              TouchRippleProps={{ classes: { root: classes.buttonLeftRipple } }}
              onClick={() => {
                inputFile.current.click();
              }}
            >
              <FormattedMessage
                id="uploadTable"
                defaultMessage="Upload Table"
              />
            </Button>
          </Box>
        );
      case 'download_table': {
        return (
          <Box
            className={classes.footer}
            borderTop={1}
            borderColor={theme.palette.color_light_grey.main}
            style={{ position: 'static' }}
          >
            <Button
              className={classes.buttonRight}
              variant="outlined"
              TouchRippleProps={{
                classes: { root: classes.buttonRightRipple },
              }}
              onClick={() => downloadTemplate()}
            >
              <FormattedMessage
                id="downloadTemplate"
                defaultMessage="Download Template"
              />
            </Button>
            <Button
              className={classes.buttonLeft}
              variant="outlined"
              TouchRippleProps={{ classes: { root: classes.buttonLeftRipple } }}
              onClick={() => {
                fetchUserCreationData(true);
              }}
            >
              <FormattedMessage
                id="downloadTable"
                defaultMessage="Download Table"
              />
            </Button>
            <Button
              className={classes.buttonRight}
              variant="contained"
              TouchRippleProps={{
                classes: { root: classes.buttonRightRipple },
              }}
              onClick={() => {
                inputFile.current.click();
              }}
            >
              <FormattedMessage
                id="uploadTable"
                defaultMessage="Upload Table"
              />
            </Button>
          </Box>
        );
      }
      default:
        return <></>;
    }
  };

  const renderReportTable = () => {
    // TODO: to update/add render function for all the columns here for customizing response data to UI needs

    let columns: any = [
      {
        title: (
          <FormattedMessage
            id="retailerStaffId"
            defaultMessage="Retailer Staff ID"
          />
        ),
        field: 'retailer_staff_id',
      },
      {
        title: <FormattedMessage id="email" defaultMessage="Email" />,
        field: 'email',
      },
      {
        title: <FormattedMessage id="username" defaultMessage="Username" />,
        field: 'username',
      },
      {
        title: (
          <FormattedMessage
            id="retailerStaffPin"
            defaultMessage="Retailer Staff Pin"
          />
        ),
        field: 'retailer_staff_pin',
      },
      {
        title: (
          <FormattedMessage
            id="allowAnalytics"
            defaultMessage="Allow Analytics"
          />
        ),
        field: 'allow_analytics',
        // align: 'left',
        render: (rowData: any) => (rowData?.allow_analytics ? 'True' : 'False'),
      },
      {
        title: <FormattedMessage id="canAdd" defaultMessage="Can Add" />,
        field: 'can_add',
        // align: 'left',
        render: (rowData: any) => (rowData?.can_add ? 'True' : 'False'),
      },
      {
        title: <FormattedMessage id="canDelete" defaultMessage="Can Delete" />,
        field: 'can_delete',
        // align: 'left',
        render: (rowData: any) => (rowData?.can_delete ? 'True' : 'False'),
      },
      {
        title: <FormattedMessage id="canEdit" defaultMessage="Can Edit" />,
        field: 'can_edit',
        render: (rowData: any) => (rowData?.can_edit ? 'True' : 'False'),
      },
      {
        title: <FormattedMessage id="canRefund" defaultMessage="Can Refund" />,
        field: 'can_refund',
        render: (rowData: any) => (rowData?.can_refund ? 'True' : 'False'),
      },
      {
        title: <FormattedMessage id="QR" defaultMessage="QR" />,
        field: 'qr',
        render: (rowData: any) => <img src={rowData?.qr} alt={rowData?.qr} />,
      },
    ];
    return (
      <MaterialTable
        title=""
        columns={columns}
        data={get(promoData, 'users', [])}
        icons={tableIcons}
        isLoading={isPromoLoading}
        options={{
          paging: false,
          search: false,
          sorting: false,
          overflowY: 'auto',
          headerStyle: {
            backgroundColor: '#A6C1FF',
            height: 40,
            fontWeight: 400,
            fontSize: 13,
            color: '#222B45',
          },
          padding: 'dense',
          loadingType: 'overlay',
          // detailPanelColumnAlignment: 'right',
          rowStyle: (rowData: any) => ({
            backgroundColor: rowData.isShiftsOpen ? '#222B45' : '#F7F9FC',
            height: 40,
            fontWeight: 700,
            fontSize: 12,
            color: rowData.isShiftsOpen ? '#FFFFFF' : '#222B45',
          }),
          tableLayout: 'auto',
        }}
        style={{ marginLeft: 24, marginRight: 24 }}
      />
    );
  };

  return (
    <Box className={classes.root}>
      <Box>
        <NavigationTopBar
          title={`Create User Information`}
          subtitle={
            <FormattedMessage
              id="createUserSubtitle"
              defaultMessage="Showing orders of"
            />
          }
          showStores={true}
          refresh={true}
          onRefreshClick={() => {
            fetchUserCreationData();
          }}
        />
      </Box>
      <Box component="div" className={classes.bodyContainer}>
        {(isPromoLoading || loader || loading) && (
          <Box className={classes.loader}>
            <CircularProgress color="primary" size={30} />
          </Box>
        )}
        <input
          type="file"
          id="file"
          ref={inputFile}
          onChange={onChangeFile.bind(this)}
          onClick={event => ((event.target as HTMLInputElement).value = null)}
          style={{ display: 'none' }}
        />
        {get(promoData, 'users', []).length >= 1 ? (
          <>
            <Box component="div" className={classes.body}>
              {renderReportTable()}
            </Box>
            <Paper className={classes.footer}>
              <InputLabel htmlFor="age-simple" style={{ marginRight: 8 }}>
                No. of Items
              </InputLabel>
              <Select
                style={{ marginRight: 8 }}
                value={pageSize}
                onChange={(event: any) => {
                  dispatch({
                    type: 'CHANGE_ROWS_PER_PAGE',
                    payload: event.target.value,
                  });
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
              <Pagination
                count={Math.ceil(get(promoData, 'count', 10) / pageSize)}
                shape="rounded"
                size="medium"
                color="primary"
                page={page}
                onChange={handlePagination}
              />
            </Paper>
            {renderFooterContent()}
          </>
        ) : (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <Typography style={{ fontWeight: 'bold' }}>
              {
                <>
                  <p style={{ fontSize: '18px', fontWeight: 700 }}>
                    Upload a table with user information to continue
                  </p>
                  <p
                    style={{
                      color: '#2E3A59',
                      fontSize: '15px',
                      fontWeight: 400,
                    }}
                  >
                    You can download the template and use it to ensure proper
                    formatting
                  </p>
                </>
              }
            </Typography>
            {renderFooterContent()}
          </Box>
        )}
      </Box>
      <Snackbar
        open={toast.visible}
        autoHideDuration={3000}
        onClose={hideToast}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert onClose={hideToast} severity={toast?.severity}>
          {toast?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default memo(UserCreation);
