import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hidden } from '@material-ui/core';
import { SelectedStores } from '../SelectedStores/index';

import InputAndPopper from '../InputAndPopper';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    minHeight: 90,
    display: 'flex',
    padding: 16,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 32,
    },
  },
  container: {
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingLeft: 60,
    },
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flex: 7,
    },
    flex: 1,
  },
  title: {
    fontSize: '26px',
    color: theme.palette.color_dark_text.main,
    fontWeight: 'bold',
    marginTop: 8,
    display: 'flex',
    alignItems: 'flex-start',
  },
  subtitle: {
    marginRight: 8,
    fontSize: '13px',
    color: theme.palette.color_text_grey.main,
  },
  subtitle2: {
    fontSize: '13px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginLeft: 5,
  },
  subtitleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  input: {
    width: 343,
    height: 40,
    display: 'flex',
    alignSelf: 'center',
  },
  inputContainer: {
    display: 'flex',
    flex: 7,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const Header = ({
  title,
  subtitle,
  rightAccessory,
  showStores = false,
  type = null,
  items = null,
  order_type = null,
  dateTimeAmountFilters = {},
  applyFiltersHandler,
}: any) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.container} component="div">
          <Typography className={classes.title}>{title}</Typography>
          <Box className={classes.subtitleContainer}>
            {showStores ? (
              <SelectedStores
                source="header"
                title={
                  <Typography className={classes.subtitle}>
                    {subtitle}
                  </Typography>
                }
              />
            ) : (
              <Typography className={classes.subtitle}>{subtitle}</Typography>
            )}
          </Box>
        </Box>
        <Hidden only={['xs', 'sm', 'md']}>
          <Box className={classes.inputContainer}>
            {rightAccessory ? (
              rightAccessory()
            ) : (
              <InputAndPopper
                type={type}
                items={items}
                order_type={order_type}
                dateTimeAmountFilters={dateTimeAmountFilters}
                applyFiltersHandler={applyFiltersHandler}
              />
            )}
          </Box>
        </Hidden>
      </Box>
    </>
  );
};

export default Header;
